import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { VRButton } from 'three/addons/webxr/VRButton.js';
import { XRControllerModelFactory } from 'three/addons/webxr/XRControllerModelFactory.js';
import { XRHandModelFactory } from 'three/addons/webxr/XRHandModelFactory.js';
import { AudioLoader, AudioListener, Audio } from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js' // Text
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js' // Text

// Global variables
let container;
let camera, scene, renderer;
let hand1, hand2;
let controller1, controller2;
let controllerGrip1, controllerGrip2;
let controls;

// New variable for the instruction text
let instructionText;

// Texture loader
let textureLoader;

// Variables to track hand movements
let lastLeftHandY = 0;
let lastRightHandY = 0;
let lastLeftHandX = 0;
let lastRightHandX = 0;
let primaryMenu = null;

// Texture loader and image texture
let imageTexture;

// Dictionary to store menu icons
const menuIcons = {
    'Player': {
        active: '2_Menu-1_Ringed/Ringed Man_on.png',
        inactive: '2_Menu-1_Ringed/Ringed Man.png',
        submenu: {
            'Items': {
                active: '3_Menu-2/Items_on.png',
                inactive: '3_Menu-2/Items.png'
            },
            'Skills': {
                active: '3_Menu-2/Skills_on.png',
                inactive: '3_Menu-2/Skills.png'
            },
            'Equipment': {
                active: '3_Menu-2/Equipment_on.png',
                inactive: '3_Menu-2/Equipment.png'
            }
        }
    },
    'Friends': {
        active: '2_Menu-1_Ringed/Ringed Men_on.png',
        inactive: '2_Menu-1_Ringed/Ringed Men.png',
        submenu: {
            'Items': {
                active: '3_Menu-2/Items_on.png',
                inactive: '3_Menu-2/Items.png'
            },
            'Skills': {
                active: '3_Menu-2/Skills_on.png',
                inactive: '3_Menu-2/Skills.png'
            },
            'Equipment': {
                active: '3_Menu-2/Equipment_on.png',
                inactive: '3_Menu-2/Equipment.png'
            }
        }
    },
    'Messages': {
        active: '2_Menu-1_Ringed/Ringed Message_on.png',
        inactive: '2_Menu-1_Ringed/Ringed Message.png',
        submenu: {
            'Items': {
                active: '3_Menu-2/Items_on.png',
                inactive: '3_Menu-2/Items.png'
            },
            'Skills': {
                active: '3_Menu-2/Skills_on.png',
                inactive: '3_Menu-2/Skills.png'
            },
            'Equipment': {
                active: '3_Menu-2/Equipment_on.png',
                inactive: '3_Menu-2/Equipment.png'
            }
        }
    },
    'Location': {
        active: '2_Menu-1_Ringed/Ringed Location_on.png',
        inactive: '2_Menu-1_Ringed/Ringed Location.png',
        submenu: {
            'Items': {
                active: '3_Menu-2/Items_on.png',
                inactive: '3_Menu-2/Items.png'
            },
            'Skills': {
                active: '3_Menu-2/Skills_on.png',
                inactive: '3_Menu-2/Skills.png'
            },
            'Equipment': {
                active: '3_Menu-2/Equipment_on.png',
                inactive: '3_Menu-2/Equipment.png'
            }
        }
    },
    'Settings': {
        active: '2_Menu-1_Ringed/Ringed Config_on.png',
        inactive: '2_Menu-1_Ringed/Ringed Config.png',
        submenu: {
            'Items': {
                active: '3_Menu-2/Items_on.png',
                inactive: '3_Menu-2/Items.png'
            },
            'Skills': {
                active: '3_Menu-2/Skills_on.png',
                inactive: '3_Menu-2/Skills.png'
            },
            'Equipment': {
                active: '3_Menu-2/Equipment_on.png',
                inactive: '3_Menu-2/Equipment.png'
            }
        }
    }
};

// Variable to track current menu state
let isMenuActive = false;

// Add this new variable to track the touch state
let isTouching = false;

// Audio variables
let audioListener, spawnSound, despawnSound, buttonClickSound;

init();

function init() {
    // Create a container for the renderer
    container = document.createElement('div');
    document.body.appendChild(container);

    // Set up the scene
    scene = new THREE.Scene();
    scene.background = new THREE.Color( 0x444444 );

    // Set up the camera
    camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 0.1, 10 );
    camera.position.set( 0, 1.6, 3 );

    // Set up OrbitControls for camera manipulation
    controls = new OrbitControls( camera, container );
    controls.target.set( 0, 1.6, 0 );
    controls.update();

    // Create a floor
    const floorGeometry = new THREE.PlaneGeometry( 4, 4 );
    const floorMaterial = new THREE.MeshStandardMaterial( { color: 0x666666 } );
    const floor = new THREE.Mesh( floorGeometry, floorMaterial );
    floor.rotation.x = - Math.PI / 2;
    floor.receiveShadow = true;
    scene.add( floor );

    // Add hemisphere light
    scene.add( new THREE.HemisphereLight( 0xbcbcbc, 0xa5a5a5, 3 ) );

    // Add directional light with shadow
    const light = new THREE.DirectionalLight( 0xffffff, 3 );
    light.position.set( 0, 6, 0 );
    light.castShadow = true;
    light.shadow.camera.top = 2;
    light.shadow.camera.bottom = - 2;
    light.shadow.camera.right = 2;
    light.shadow.camera.left = - 2;
    light.shadow.mapSize.set( 4096, 4096 );
    scene.add( light );

    // Set up the renderer
    renderer = new THREE.WebGLRenderer( { antialias: true } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setAnimationLoop( animate );
    renderer.shadowMap.enabled = true;
    renderer.xr.enabled = true;
    container.appendChild( renderer.domElement );

    // Set up VR button
    const sessionInit = {
        requiredFeatures: [ 'hand-tracking' ]
    };
    const vrButton = VRButton.createButton(renderer, sessionInit);
    document.body.appendChild(vrButton);

    // Set up controllers
    controller1 = renderer.xr.getController( 0 );
    scene.add( controller1 );

    controller2 = renderer.xr.getController( 1 );
    scene.add( controller2 );

    const controllerModelFactory = new XRControllerModelFactory();
    const handModelFactory = new XRHandModelFactory();

    // Set up Hand 1
    controllerGrip1 = renderer.xr.getControllerGrip( 0 );
    controllerGrip1.add( controllerModelFactory.createControllerModel( controllerGrip1 ) );
    scene.add( controllerGrip1 );

    hand1 = renderer.xr.getHand( 0 );
    hand1.add( handModelFactory.createHandModel( hand1, 'mesh' ) );
    scene.add( hand1 );

    // Set up Hand 2
    controllerGrip2 = renderer.xr.getControllerGrip( 1 );
    controllerGrip2.add( controllerModelFactory.createControllerModel( controllerGrip2 ) );
    scene.add( controllerGrip2 );

    hand2 = renderer.xr.getHand( 1 );
    hand2.add( handModelFactory.createHandModel( hand2, 'mesh' ) );
    scene.add( hand2 );

    // Create a line geometry for controllers
    const geometry = new THREE.BufferGeometry().setFromPoints( [ new THREE.Vector3( 0, 0, 0 ), new THREE.Vector3( 0, 0, - 1 ) ] );
    const line = new THREE.Line( geometry );
    line.name = 'line';
    line.scale.z = 5;

    // Add the line to both controllers
    controller1.add( line.clone() );
    controller2.add( line.clone() );

    // Create a texture loader
    textureLoader = new THREE.TextureLoader();
    
    // Load all textures
    for (const menu in menuIcons) {
        menuIcons[menu].activeTexture = textureLoader.load(menuIcons[menu].active);
        menuIcons[menu].inactiveTexture = textureLoader.load(menuIcons[menu].inactive);
        if (menuIcons[menu].submenu) {
            for (const submenuItem in menuIcons[menu].submenu) {
                menuIcons[menu].submenu[submenuItem].activeTexture = textureLoader.load(menuIcons[menu].submenu[submenuItem].active);
                menuIcons[menu].submenu[submenuItem].inactiveTexture = textureLoader.load(menuIcons[menu].submenu[submenuItem].inactive);
            }
        }
    }

    /**
     * Fonts
     */
    const fontLoader = new FontLoader()

    fontLoader.load(
        '/fonts/SAOUI-Regular.json',
        (font) =>
        {
            const textGeometry = new TextGeometry(
                'Link Start',
                {
                    font: font,
                    size: 1,
                    depth: 0.2,
                    curveSegments: 12,
                    bevelEnabled: true,
                    bevelThickness: 0.03,
                    bevelSize: 0.02,
                    bevelOffset: 0,
                    bevelSegments: 5
                }
            )
            textGeometry.center()
            const textMaterial = new THREE.MeshBasicMaterial()
            const text = new THREE.Mesh(textGeometry, textMaterial)
            scene.add(text)
        }
    )

    // Add window resize event listener
    window.addEventListener( 'resize', onWindowResize );

    // Set up audio
    audioListener = new AudioListener();
    camera.add(audioListener);

    const audioLoader = new AudioLoader();
    
    spawnSound = new Audio(audioListener);
    audioLoader.load('sfx/menu-open.mp3', function(buffer) {
        spawnSound.setBuffer(buffer);
    });

    despawnSound = new Audio(audioListener);
    audioLoader.load('sfx/menu-open.mp3', function(buffer) {
        despawnSound.setBuffer(buffer);
    });

    buttonClickSound = new Audio(audioListener);
    audioLoader.load('sfx/menu-tap.mp3', function(buffer) {
        buttonClickSound.setBuffer(buffer);
    });

    // Create the "Enter VR" instruction
    createEnterVRInstruction();
}

function createEnterVRInstruction() {
    instructionText = createHTMLTextElement("Click on the 'Enter VR' button to start the experience. <br/><br/> Swipe down to spawn the menu. <br/><br/>Swipe left to remove the menu. <br/><br/> To get updates, join my newsletter at https://news.osco.blog <br/><br/> Link Start!");
    instructionText.style.top = '50%';
}

function createHTMLTextElement(text) {
    const element = document.createElement('div');
    element.innerHTML = text;
    element.style.position = 'absolute';
    element.style.left = '50%';
    element.style.transform = 'translate(-50%, -50%)';
    element.style.color = 'white';
    element.style.fontSize = '24px';
    element.style.textAlign = 'center';
    element.style.fontFamily = 'SAOUI-Bold, Arial, sans-serif'; // Use the custom font
    element.style.pointerEvents = 'auto';

    // Add click event listener for redirection
    element.addEventListener('click', function() {
        window.location.href = 'https://news.osco.blog';
    });

    container.appendChild(element);
    return element;
}

// Handle window resizing
function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize( window.innerWidth, window.innerHeight );
}

// Animation loop
function animate() {
    let spawnHand = null;
    let destroyBox = false;

    // Check left hand (hand1) movement and touch
    if (hand1.joints['index-finger-tip']) {
        const currentY = hand1.joints['index-finger-tip'].position.y;
        const currentX = hand1.joints['index-finger-tip'].position.x;
        if (currentY < lastLeftHandY - 0.05) {
            // Downward movement detected
            spawnHand = hand1;
        } else if (currentX < lastLeftHandX - 0.05) {
            // Left swipe detected
            destroyBox = true;
        }
        lastLeftHandY = currentY;
        lastLeftHandX = currentX;

        // Check for touch
        checkTouch(hand1);
    }

    // Check right hand (hand2) movement and touch
    if (hand2.joints['index-finger-tip']) {
        const currentY = hand2.joints['index-finger-tip'].position.y;
        const currentX = hand2.joints['index-finger-tip'].position.x;
        if (currentY < lastRightHandY - 0.05) {
            // Downward movement detected
            spawnHand = hand2;
        } else if (currentX < lastRightHandX - 0.05) {
            // Left swipe detected
            destroyBox = true;
        }
        lastRightHandY = currentY;
        lastRightHandX = currentX;

        // Check for touch
        checkTouch(hand2);
    }

    // Destroy the current box if a swipe was detected
    if (destroyBox && primaryMenu) {
        scene.remove(primaryMenu);
        primaryMenu = null;
        despawnSound.play(); // Play despawn sound
    }

    // Spawn a new image if a downward movement was detected and no menu is currently active
    if (spawnHand && !primaryMenu) {
        primaryMenu = spawnImage(spawnHand);
        spawnSound.play(); // Play spawn sound
    }

    renderer.render(scene, camera);
}

// Add this new function to check for touch
function checkTouch(hand) {
    if (primaryMenu) {
        const fingerTip = hand.joints['index-finger-tip'].position;
        primaryMenu.children.forEach((child, index) => {
            if (child instanceof THREE.Mesh) {
                const worldPosition = new THREE.Vector3();
                child.getWorldPosition(worldPosition);
                const distance = fingerTip.distanceTo(worldPosition);

                if (distance < 0.05 && !isTouching) {
                    isTouching = true;
                    toggleMenuState(index);
                } else if (distance >= 0.05 && isTouching) {
                    isTouching = false;
                }
            } else if (child instanceof THREE.Group && child.visible) {
                // Handle submenu interactions
                child.children.forEach((submenuItem, submenuIndex) => {
                    const submenuWorldPosition = new THREE.Vector3();
                    submenuItem.getWorldPosition(submenuWorldPosition);
                    const submenuDistance = fingerTip.distanceTo(submenuWorldPosition);

                    if (submenuDistance < 0.05 && !isTouching) {
                        isTouching = true;
                        console.log(`Submenu item ${submenuIndex} clicked`);
                        // Add your submenu item click logic here
                    } else if (submenuDistance >= 0.05 && isTouching) {
                        isTouching = false;
                    }
                });
            }
        });
    }
}

// Add this new function to toggle the menu state
function toggleMenuState(index) {
    const menuItems = Object.keys(menuIcons);
    const menuItem = menuItems[index];
    
    // Deactivate all menu items first
    menuItems.forEach(item => {
        menuIcons[item].isActive = false;
    });
    
    // Activate the clicked menu item
    menuIcons[menuItem].isActive = true;

    // Play button click sound
    buttonClickSound.play();

    // Remove existing submenu
    const existingSubmenu = primaryMenu.children.find(child => child.name === 'submenuGroup');
    if (existingSubmenu) {
        primaryMenu.remove(existingSubmenu);
    }
    
    // Create and add new submenu for the active menu item
    if (menuIcons[menuItem].submenu) {
        const submenuGroup = createSubmenu(menuItem, index);
        primaryMenu.add(submenuGroup);
    }
    
    updateImageTexture();
}

// Add this new function to create submenus
function createSubmenu(menuItem, index) {
    const submenuGroup = new THREE.Group();
    submenuGroup.name = 'submenuGroup';
    const iconSize = 0.1;
    const spacing = 0.02;
    const submenuItems = Object.keys(menuIcons[menuItem].submenu);
    
    const totalHeight = (submenuItems.length * iconSize) + ((submenuItems.length - 1) * spacing);
    let yOffset = totalHeight / 2 - iconSize / 2;
    
    submenuItems.forEach((submenuItem, submenuIndex) => {
        const submenuPlaneGeometry = new THREE.PlaneGeometry(iconSize, iconSize);
        const submenuTexture = menuIcons[menuItem].submenu[submenuItem].inactiveTexture;
        const submenuPlaneMaterial = new THREE.MeshBasicMaterial({
            map: submenuTexture,
            side: THREE.DoubleSide,
            transparent: true,
            alphaTest: 0.5
        });
        const submenuPlane = new THREE.Mesh(submenuPlaneGeometry, submenuPlaneMaterial);
        
        submenuPlane.position.set(0, yOffset, 0);
        submenuGroup.add(submenuPlane);
        
        yOffset -= (iconSize + spacing);
    });
    
    // Calculate the Y position of the active primary menu icon
    const menuIconYPosition = (primaryMenu.children.length - 1 - index) * (iconSize + spacing);
    
    // Center the submenu group around the Y position of the active primary menu icon
    // and shift it down by one menu icon
    submenuGroup.position.set(iconSize + spacing, menuIconYPosition - (totalHeight / 2) - (iconSize / 2) - .0225, 0);
    
    return submenuGroup;
}

// Add this new function to update the image texture
function updateImageTexture() {
    if (primaryMenu) {
        primaryMenu.children.forEach((child, index) => {
            if (child instanceof THREE.Mesh) {
                const menuItems = Object.keys(menuIcons);
                const menuItem = menuItems[index];
                const texture = menuIcons[menuItem].isActive ? menuIcons[menuItem].activeTexture : menuIcons[menuItem].inactiveTexture;
                
                if (child.material) {
                    child.material.map = texture;
                    child.material.needsUpdate = true;
                }
            }
        });
    }
}

function spawnImage(hand) {
    const menuGroup = new THREE.Group();
    const iconSize = 0.1;
    const spacing = 0.02;
    const menuItems = Object.keys(menuIcons);
    const totalHeight = (menuItems.length * iconSize) + ((menuItems.length - 1) * spacing);
    let yOffset = totalHeight / 2 - iconSize / 2;

    menuItems.forEach((menuItem, index) => {
        const planeGeometry = new THREE.PlaneGeometry(iconSize, iconSize);
        const texture = menuIcons[menuItem].inactiveTexture;
        
        const planeMaterial = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.DoubleSide,
            transparent: true,
            alphaTest: 0.5
        });
        const plane = new THREE.Mesh(planeGeometry, planeMaterial);
        
        plane.position.set(0, yOffset, 0);
        menuGroup.add(plane);
        
        yOffset -= (iconSize + spacing);
    });

    const handPosition = hand.joints['index-finger-tip'].position;
    const handRotation = hand.quaternion;
    
    const forwardVector = new THREE.Vector3(0, 0, -1);
    forwardVector.applyQuaternion(handRotation);
    
    menuGroup.position.copy(handPosition).add(forwardVector.multiplyScalar(0.2));
    menuGroup.quaternion.copy(handRotation);
    
    scene.add(menuGroup);
    return menuGroup;
}